<template>
  <CModal
      :show.sync="MediaMetaConfig.Display"
      :centered="true"
      :title="$t('System/Themes.ImageMeta')"
      color="dark"
  >
    <CForm>
      <CInput v-model="MediaMetaConfig.ImageMeta.Alt" size="sm" :label="$t('Global.Alt')" :placeholder="$t('Global.Alt')" type="text" />
      <CInput v-model="MediaMetaConfig.ImageMeta.URL" size="sm" :label="$t('Media.SetImageSrc')" :placeholder="$t('Media.SetImageSrc')" type="text" />
      <CInput v-model="MediaMetaConfig.ImageMeta.Link" size="sm" :label="$t('Global.Link')" :description="$t('Message.System/LinkInfo')" :placeholder="$t('Global.Link')" type="text" />
      <CInputRadioGroup v-model="MediaMetaConfig.ImageMeta.Target" :label="$t('Global.OpenTarget')" class="mb-3" size="sm" :options="TargetOption" :checked.sync="MediaMetaConfig.ImageMeta.Target" :custom="true" inline />
      <CInput v-model="MediaMetaConfig.ImageMeta.Title" size="sm" :label="$t('Global.Title')" :placeholder="$t('Global.Title')" type="text" />
      <CTextarea v-model="MediaMetaConfig.ImageMeta.Content" :label="$t('Global.Content')" :placeholder="$t('Global.Content')" size="sm" rows="5" />
    </CForm>
    <template #footer>
      <CButton @click="Confirm()" color="success">{{ $t('Global.Confirm') }}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "MediaMeta",
  props: ['Config'],
  data() {
    return {
      ComponentID: null,
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {
          Alt: '',
          Link: '',
          Title: '',
          Content: '',
          Target: '_self'
        }
      }
    }
  },
  computed: {
    TargetOption() {
      return [
        { value: '_self', label: this.$t('Global.SelfTarget')},
        { value: '_blank', label: this.$t('Global.BlankTarget')}
      ]
    }
  },
  mounted() {
    this.ComponentID = this._uid
    this.MediaMetaConfig = this.Config
  },
  methods: {
    Toggle(status = true) {
      return new Promise((resolve) => {
        (status) ? this.$emit('Opening') : this.$emit('Closing')
        this.MediaMetaConfig.Display = status
        resolve()
      })
    },
    Confirm() {
      this.MediaMetaConfig.Display = false
      this.$emit('OK', this.MediaMetaConfig.ImageMeta)
    }
  }
}
</script>
